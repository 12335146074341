import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useMutation, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { checkStatusOrder } from 'src/functions/order-statuts';
import { IOrderDetail, ITrackingDetail } from 'src/model/order.modal';
import { getBank, getOrderPayment } from 'src/services/app.services';
import {
  cancelOrder,
  changeNoteItem,
  changeQuantityItem,
  createOrderPayment,
  deleteTracking,
  getItemRefund,
  getOrderCustomer,
  getOrderDetail,
  getOrderTracking,
  removeProductInOrder,
  updateOrder,
} from 'src/services/order.servies';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';
import ModalAlert from 'src/shared-components/Modal';
import SelectCustom from 'src/shared-components/SelectCustom';
import { useToast } from 'src/shared-components/Toast';
import { columnsProductDetail, columnTracking, discountData } from 'src/utils/app-data';
import { client_type } from 'src/utils/groups';
import { convertCurrency, currencyMark, numberFormat } from 'src/utils/utils';
import AddCustomer from './components/addCustomer';
import AddTracking from './components/addTracking';
import CreateProduct from './components/createProduct';
import ProductList from './components/orderProductList';
import RefundOrder from './components/refundOrder';
import TrackingTable from './components/trackingTable';
import UpdateCustomer from './components/updateCustomer';
import UpdateTracking from './components/updateTracking';
import UpdateTrackingRefund from './components/updateTrackingRefund';
import './order.scss';

const OrderDetail = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const idOrder: any = useParams();
  const { data: detail, refetch } = useQuery(['detailProduct', idOrder?.id], () => getOrderDetail(idOrder?.id));
  const { data: itemRefund, refetch: refundFetch } = useQuery(['itemRefund', idOrder?.id], () =>
    getItemRefund(idOrder?.id),
  );

  const orderDetail: IOrderDetail = detail?.data ?? '';
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  // UPDATE: hàm này để gán số lượng products ban đầu cho productList khi productList thay đổi
  useEffect(() => {
    for (let i = 0; i < detail?.data.orderProducts.length; i++) {
      if (detail) {
        detail.data.orderProducts[i].initialValueQuantity = detail.data.orderProducts[i].quantity;
      }
    }
  }, [detail]);

  const schema = yup.object().shape({
    paymentPrice: yup
      .string()
      .test('isLarger', 'Vượt quá số tiền cần thanh toán', function (value: any, testContext: any) {
        return numberFormat(value) <= Number(orderDetail?.total) - Number(orderDetail?.totalPaid);
      })
      .typeError('Vui lòng nhập số tiền')
      .required('Vui lòng nhập số tiền'),
    paymentType: yup.object().typeError('Vui lòng chọn phương thức').required('Vui lòng chọn phương thức'),
  });

  const { data: trackingOrder, refetch: refetchTracking } = useQuery(['trackingDetail', idOrder?.id], () =>
    getOrderTracking(idOrder?.id),
  );
  const { data: orderPayment, refetch: refetchPayment } = useQuery(['orderPayment', idOrder?.id], () =>
    getOrderPayment(idOrder?.id),
  );
  const { data: bankData } = useQuery(['bank'], () => getBank({}));

  const trackingDetail: ITrackingDetail = trackingOrder?.data?.data || '';

  const dateNow = moment(new Date()).format('DD-MM-YYYY hh:mm');

  const [address, setAddress] = useState<any>({
    province: null,
    district: null,
  });
  const [note, setNote] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [skuQuantity, setSkuQuantity] = useState<number>(0);
  const [idItem, setIdItem] = useState<string>('');
  const [value, setValueChange] = useState<any>(0);
  const [branchId, setBranchId] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [itemProduct, setItemProduct] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [detailTracking, setDetailTracking] = useState<any>();
  const [filterParams, setFilterParams] = useState<any>(null);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false);
  const [showUpdateCustomer, setShowUpdateCustomer] = useState<boolean>(false);
  const [showAddTracking, setShowAddTracking] = useState<boolean>(false);
  const [showRefundOrder, setShowRefundOrder] = useState<boolean>(false);
  const [changeNote, setChangeNote] = useState<any>('');
  const [showDrawer, setShowDrawer] = useState(false);

  const [showUpdateTracking, setShowUpdateTracking] = useState<{ type: 'refund' | 'delivery'; isOpen: boolean }>({
    type: 'delivery',
    isOpen: false,
  });
  const [productList, setProductList] = useState<any[]>(orderDetail?.orderProducts || []);
  const [priceAfterDiscount, setPriceAfterDiscount] = useState<any>();

  const [showDiscountReason, setShowDiscountReason] = useState<boolean>(false);

  const defaultValues: any = useMemo(
    () => ({
      price: Number(orderDetail?.total) - Number(orderDetail?.totalPaid),
      note: '',
      paymentPrice: '',
      createdDate: dateNow,
      paymentType: bankData?.data?.TranferType[1] || '',
      paymentBank: '',
      branch: '',
      resourceBank: '',
      province: '',
      district: '',
      noteUpdate: orderDetail?.note || '',
      notePayment: '',
      discountValue: orderDetail?.discountValue || '',
      discountReason: orderDetail?.discountReason || '',
      discountType: orderDetail?.discountType
        ? discountData?.find((item: any) => item.type === orderDetail?.discountType)
        : discountData[1],
    }),
    [orderDetail],
  );

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { formState, watch, setValue, handleSubmit, reset, getValues } = methods;
  const { errors } = formState;

  const { data: orderCustomerList } = useQuery(['customerList', { ...filterParams }], (params: any) =>
    getOrderCustomer({ ...filterParams }),
  );

  const paymentPriceWatch = watch('paymentPrice');
  const { branch, noteUpdate } = watch();
  const discountValue: any = watch('discountValue');
  const discountReason: any = watch('discountReason');
  const discountType: any = watch('discountType');

  useEffect(() => {
    setValue('noteUpdate', orderDetail.note);
  }, [orderDetail]);
  useEffect(() => {
    setValue('paymentPrice', currencyMark(paymentPriceWatch));
  }, [paymentPriceWatch]);

  useEffect(() => {
    setValue('discountValue', currencyMark(orderDetail?.discountValue?.toString() ?? '0'));
    const discountTypeTmp = orderDetail?.discountType
      ? discountData?.find((item: any) => item.type === orderDetail?.discountType)
      : discountData[1];
    setValue('discountType', discountTypeTmp);
    setValue('discountReason', orderDetail?.discountReason);
  }, [detail]);

  useEffect(() => {
    if (discountType?.type === 'AMOUNT') {
      setValue('discountValue', currencyMark(discountValue));
      const afterDiscount =
        (orderDetail?.discountValue ? Number(orderDetail?.totalAmount) : Number(orderDetail?.total)) -
        numberFormat(discountValue) +
        Number(orderDetail?.inter_shipping_fee);
      setPriceAfterDiscount(afterDiscount);
    } else {
      if (discountValue >= 100) {
        setValue('discountValue', currencyMark('100'));
      } else if (discountValue < 0) {
        setValue('discountValue', currencyMark('0'));
      } else {
        setValue('discountValue', currencyMark(discountValue));
      }
      const afterDiscount =
        (orderDetail?.discountValue ? Number(orderDetail?.totalAmount) : Number(orderDetail?.total)) *
          ((100 - Math.min(Math.max(0, numberFormat(discountValue)), 100)) / 100) +
        Number(orderDetail?.inter_shipping_fee);
      setPriceAfterDiscount(afterDiscount);
    }
  }, [discountValue]);

  useEffect(() => {
    setBranchId(branch?.codeName);
  }, [branch, noteUpdate]);

  const mutationCreateOrderPayment = useMutation({
    mutationFn: createOrderPayment,
    onSuccess: (data: any) => {
      toast('success', 'Thêm mới thành công');
      reset();
      setProductList([]);
      setItemProduct([]);
      setCustomerList([]);
      refetchPayment();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const mutationRemoveProduct = useMutation({
    mutationFn: removeProductInOrder,
    onSuccess: (data: any) => {
      toast('success', 'Xóa sản phẩm khỏi danh sách thành công');
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  const mutationUpdateOrder = useMutation({
    mutationFn: updateOrder,
    onSuccess: (data: any) => {
      toast('success', 'Cập nhật thành công');
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response?.data?.message || 'Có lỗi xảy ra ');
      }
    },
  });

  const mutationCancelOrder = useMutation({
    mutationFn: cancelOrder,
    onSuccess: (data: any) => {
      toast('success', 'Hủy đơn thành công');
      refetch();
      reset();
      setOpenModal(false);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response?.data?.message || 'Có lỗi xảy ra ');
      }
    },
  });

  const mutationCancelTracking = useMutation({
    mutationFn: deleteTracking,
    onSuccess: (data: any) => {
      toast('success', 'Hủy đơn thành công');
      reset();
      refetchTracking();
      refetch();
      setSelected([]);
      refundFetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response?.data?.message || 'Có lỗi xảy ra ');
      }
    },
  });

  const mutationChangeNote = useMutation({
    mutationFn: changeNoteItem,
    onSuccess: (data: any) => {
      toast('success', 'Cập nhật thành công');
      refetch();
      reset();
      setOpenModal(false);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response?.data?.message || 'Có lỗi xảy ra ');
      }
    },
  });

  useEffect(() => {
    if (!_.isEmpty(orderCustomerList?.data)) {
      let customer = [...orderCustomerList?.data];
      customer?.unshift({ id: 0, name: '', phone: '' });
      setCustomerList(customer);
    } else {
      let customer = [];
      customer?.unshift({ id: 0, name: '', phone: '' });
      setCustomerList(customer);
    }
  }, [filterParams]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValueChange(newValue);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateTracking = (type: (typeof showUpdateTracking)['type']) => (data: any) => {
    setShowUpdateTracking(pre => ({ type, isOpen: !pre.isOpen }));
    setDetailTracking(data);
  };

  const onPressUpdateNote = (id: any) => {
    const params = {
      note: changeNote,
      id: idOrder?.id,
      idItem: id,
    };
    mutationChangeNote.mutate(params);
  };

  const onDeleteProduct = (idx: number) => {
    let tmpRow: any;
    let item: any;
    // remove prod
    tmpRow = productList?.filter((itemId: any, index: number) => console.log('object', index, idx));
    // remove item prod
    item = itemProduct?.filter((itemId: any, index: number) => index !== idx);

    setProductList(tmpRow);
    setItemProduct(item);
    const params = {
      id: orderDetail.id,
      items: [idx],
    };
    mutationRemoveProduct.mutate(params);
  };

  const handleClickTracking = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = trackingOrder?.data?.data.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const onAddShipping = () => {
    if (orderDetail?.status === 'IN_PROCESS') {
      setShowAddTracking(true);
    } else {
      toast('warning', 'Đơn không tồn tại hoặc đang chờ duyệt');
    }
  };

  const onOpenRefundOrder = () => {
    setShowRefundOrder(true);
  };

  const pricePay = () => {
    let totalNeedPaid: Number = 0;
    let totalPaid: number = 0;
    if (Number(orderDetail?.totalPaid) > Number(orderDetail?.total)) {
      totalPaid = Number(priceAfterDiscount);
      totalNeedPaid = 0;
      return { totalPaid, totalNeedPaid };
    } else {
      totalPaid = Number(orderDetail?.totalPaid);
      totalNeedPaid = Number(priceAfterDiscount) - Number(orderDetail?.totalPaid);
      return { totalPaid, totalNeedPaid };
    }
  };

  const onSubmit = (values: any) => {
    const params: any = {
      paymentType: values.paymentType?.type,
      paymentBank: values.paymentBank?.id,
      price: numberFormat(values.paymentPrice),
      note: values?.notePayment,
      id: orderDetail?.id,
      user: user?.userName,
    };
    mutationCreateOrderPayment.mutate(params);
  };

  const onCopyOrder = () => {
    navigate('/order/copyOrder', { state: { orderDetail } });
  };

  const onCancelOrder = () => {
    const params: any = {
      id: orderDetail?.id,
    };
    mutationCancelOrder.mutate(params);
  };

  const onUpdateOrder = (approve: boolean) => {
    const params: any = {
      branch: undefined,
      note: noteUpdate,
      approve: approve,
      user: user.userName,
      id: orderDetail?.id,
      discountType: discountType?.type,
      discountReason: discountReason,
      discountValue: numberFormat(discountValue.toString()),
    };
    mutationUpdateOrder.mutate(params);
  };

  const onChangeNote = (text: string) => {
    setChangeNote(text);
  };

  const onChangeQuantity = (text: number) => {
    setQuantity(text);
  };

  const onChangeSkuQuantity = (text: number) => {
    setSkuQuantity(text);
  };

  const getIdItem = (text: string) => {
    setIdItem(text);
  };

  const onUpdateNote = () => {
    const params: any = {
      note: noteUpdate,
      id: orderDetail?.id,
      discountType: discountType?.type,
      discountValue: numberFormat(discountValue.toString()),
      discountReason: discountReason,
      user: user.userName,
    };
    mutationUpdateOrder.mutate(params);
  };

  const onCancelTracking = () => {
    if (!_.isEmpty(selected)) {
      const params: any = {
        ids: selected,
        orderId: orderDetail?.id,
      };
      mutationCancelTracking.mutate(params);
    } else {
      toast('warning', 'Vui lòng chọn mã đơn');
    }
  };

  const onShowDiscountReason = (productList: any[]) => {
    if (discountValue > 0) {
      setShowDiscountReason(true);
    } else {
      updateOrderQuantity(productList);
    }
  };

  const onCloseDiscountReason = (productList: any[]) => {
    updateOrderQuantity(productList);
  };

  // UPDATE: thêm params truyền đi, chỉnh sửa logic, code
  const updateOrderQuantity = async (productList: any[]) => {
    let flag = 0;
    let temp = '';
    for (let i = 0; i < productList.length; i++) {
      temp = _.toString(productList[i].quantity);
      if (
        productList[i].quantity <= 0 ||
        productList[i].quantity > productList[i].product.quantity + productList[i].initialValueQuantity
      ) {
        toast('error', 'Số lượng không hợp lệ');
        break;
      } else if (temp === '') {
        toast('error', 'Không được để trống');
        break;
      } else if (temp.includes('-') || temp.includes('+')) {
        toast('error', 'Có kí tự không hợp lệ');
        break;
      } else {
        flag++;
      }
    }
    onUpdateNote();
    if (flag === productList.length) {
      setProductList(productList);
      let params = null;
      for (let i = 0; i < productList.length; i++) {
        params = {
          quantity: productList[i].quantity,
          idSkuProduct: productList[i].product.id,
          idItem: productList[i].id,
          skuQuantity: +productList[i].product.quantity,
          initialQuantity: +productList[i].initialValueQuantity,
        };
        await changeQuantityItem(params);
        reset();
      }

      setShowDiscountReason(false);
      // try {
      //   const paramsUpdatePayment: any = {
      //     branch: undefined,
      //     note: noteUpdate,
      //     approve: true,
      //     user: user.userName,
      //     id: orderDetail?.id,
      //     discountType: discountType?.type,
      //     discountValue: numberFormat(discountValue.toString()),
      //   };
      //   mutationUpdateOrder.mutate(paramsUpdatePayment);
      // } catch (error) {
      //   toast('error', 'Cập nhật thất bại');
      // }
    }
  };

  const TabPanelInfo = () => {
    return (
      <FormProvider {...methods}>
        <form>
          <div className="content mt-2">
            <Grid container spacing={2} className="justify-content-center">
              <Grid item xs={12}>
                <FormControlCustom label={'Ghi chú'} fullWidth>
                  <InputField
                    control={methods.control}
                    name={'noteUpdate'}
                    style={{ width: '100%' }}
                    className={'input-detail'}
                  />
                </FormControlCustom>
              </Grid>

              {orderDetail?.status === 'IN_REVIEW' ? (
                <>
                  <LoadingButton
                    loading={mutationUpdateOrder.isLoading}
                    loadingPosition="start"
                    variant="contained"
                    size="medium"
                    className={`btn-tertiary-normal mt-1`}
                    style={{ height: '2rem', maxWidth: '175px', marginLeft: '20px' }}
                    onClick={() => onUpdateOrder(true)}
                  >
                    Duyệt đơn hàng
                  </LoadingButton>
                  <LoadingButton
                    loading={mutationUpdateOrder.isLoading}
                    loadingPosition="start"
                    variant="contained"
                    size="medium"
                    className={`btn-tertiary-normal mt-1`}
                    style={{ height: '2rem', maxWidth: '175px', marginLeft: '10px' }}
                    onClick={() => onShowDiscountReason(orderDetail?.orderProducts)}
                  >
                    Cập nhật
                  </LoadingButton>
                </>
              ) : (
                <LoadingButton
                  loading={mutationUpdateOrder.isLoading}
                  loadingPosition="start"
                  variant="contained"
                  size="medium"
                  className={`btn-tertiary-normal mt-1`}
                  style={{ height: '2rem', maxWidth: '175px' }}
                  onClick={() => onShowDiscountReason(orderDetail?.orderProducts)}
                >
                  Cập nhật
                </LoadingButton>
              )}
            </Grid>
          </div>
        </form>
      </FormProvider>
    );
  };

  const checkTrackingOrder = () => {
    return (
      <div className={'page-layout'}>
        <Grid container className={'align-items-center header_title tracking_order'}>
          <Grid xs={4} sm={7} md={5}>
            <h2 className={'txt-title'}>Vận chuyển</h2>
          </Grid>
          <Grid xs={12} sm={5} md={7}>
            <div className="item-btn-right">
              <Button className={'btn-create'} style={{ whiteSpace: 'nowrap' }} onClick={onCancelTracking}>
                <span className={'txt'}>Hủy ship</span>
              </Button>
              {orderDetail?.status !== 'CANCEL' && (
                <>
                  <Button className={'btn-create'} style={{ whiteSpace: 'nowrap' }} onClick={onAddShipping}>
                    <span className={'txt'}>Thêm ship</span>
                  </Button>
                  <Button className={'btn-create'} style={{ whiteSpace: 'nowrap' }} onClick={onOpenRefundOrder}>
                    <span className={'txt'}>Hoàn hàng</span>
                  </Button>
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <Divider />
        <TrackingTable
          rows={trackingDetail || []}
          columns={columnTracking}
          isExpand={true}
          checkboxSelection={true}
          handleClick={handleClickTracking}
          handleSelectAllClick={handleSelectAllClick}
          selected={selected}
          handleUpdateTracking={handleUpdateTracking}
        />
      </div>
    );
  };

  const checkInfoOrder = () => {
    return (
      <div className={'page-layout'}>
        <Grid container overflow={'hidden'}>
          <Grid flexDirection={{ xs: 'column', md: 'row' }} item>
            <Tabs onChange={() => {}} textColor="primary">
              <Tab label="Thông tin" />
              <Tab
                className="link-order-detail"
                component="a"
                label="Link đơn hàng"
                target="_blank"
                href={`https://shipus.vn/don-hang?id=${orderDetail?.orderNumber}&phone=${orderDetail?.customerAddress?.phone}`}
              />
            </Tabs>
          </Grid>
        </Grid>
        <Divider />
        {TabPanelInfo()}
      </div>
    );
  };

  const checkPayment = () => {
    return (
      <div className={'mt-3'}>
        <Grid container overflow={'hidden'}>
          <Grid flexDirection={{ xs: 'column', md: 'row' }} item>
            <Tabs value={value} onChange={handleChange} textColor="primary">
              <Tab label="Thanh toán" className="left-border" />
              <Tab label="Lịch sử" className="right-border" />
            </Tabs>
          </Grid>
        </Grid>
        <Divider />
        {tabPayment()}
      </div>
    );
  };

  const tabPayment = () => {
    if (value === 0) {
      return (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="content mt-2">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlCustom label={'Thời gian'} fullWidth>
                    <InputField
                      style={{ width: '100%' }}
                      name={'createdDate'}
                      placeholder={'Nhập thời gian'}
                      error={Boolean(errors.createdDate)}
                      helperText={''}
                      disabled={true}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>

                <Grid item xs={12}>
                  <FormControlCustom label={'Số tiền'} fullWidth>
                    <InputField
                      style={{ width: '100%' }}
                      name={'paymentPrice'}
                      placeholder={`Số tiền cần thanh toán ${convertCurrency(pricePay()?.totalNeedPaid)}`}
                      error={Boolean(errors?.paymentPrice)}
                      helperText={errors?.paymentPrice?.message as string}
                    />
                  </FormControlCustom>
                </Grid>

                <Grid item xs={12}>
                  <FormControlCustom label={'Ngân hàng'} fullWidth>
                    <SelectCustom
                      style={{ width: '100%' }}
                      name={'paymentBank'}
                      placeholder={'Chọn bank'}
                      options={bankData?.data?.Bank || []}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={12}>
                  <FormControlCustom label={'PTTT'} fullWidth>
                    <SelectCustom
                      style={{ width: '100%' }}
                      name={'paymentType'}
                      placeholder={'Chọn PTTT'}
                      options={bankData?.data?.TranferType || []}
                      optionLabelKey={'name'}
                      disableClear={true}
                      defaultValue={bankData?.data?.TranferType[1]}
                      error={Boolean(errors?.paymentType)}
                      helperText={errors?.paymentType?.message as string}
                    />
                  </FormControlCustom>
                </Grid>

                <Grid item xs={12}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField
                      style={{ width: '100%' }}
                      multiline
                      rows={3}
                      name={'notePayment'}
                      placeholder={'Nhập ghi chú'}
                      error={Boolean(errors.note)}
                      helperText={''}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            {orderDetail?.status !== 'CANCEL' && (
              <div className="flex justify-content-center">
                <LoadingButton
                  loading={mutationCreateOrderPayment.isLoading}
                  loadingPosition="start"
                  variant="contained"
                  size="medium"
                  className={`btn-tertiary-normal mt-1`}
                  style={{ height: '2rem', maxWidth: '175px' }}
                  type="submit"
                >
                  Thêm thanh toán
                </LoadingButton>
              </div>
            )}
          </form>
        </FormProvider>
      );
    } else {
      return (
        <FormProvider {...methods}>
          <form>
            <div className="content mt-2">
              <TableContainer className="datatable-v2" component={Paper}>
                <Table size="small" aria-label="a dense table" padding="none" style={{ width: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align={'center'} padding="checkbox" style={{ width: '33.33%' }}>
                        Thời gian
                      </TableCell>
                      <TableCell align={'center'} padding="checkbox" style={{ width: '33.33%' }}>
                        Nội dung
                      </TableCell>
                      <TableCell align={'center'} padding="checkbox" style={{ width: '33.33%' }}>
                        Số tiền
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderPayment?.data?.data.map((item: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell align={'center'}>{moment(item?.createdAt).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align={'center'}>{item?.note}</TableCell>
                        <TableCell align={'center'}>{convertCurrency(item.price)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </form>
        </FormProvider>
      );
    }
  };

  const checkPaymentOrder = () => {
    return (
      <Grid container md={12}>
        <div className={'page-layout'} style={{ width: '100%' }}>
          <div className={'align-items-center header_title'}>
            <h2 className={'txt-title'}>THANH TOÁN</h2>
          </div>
          <Divider />
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '12px 0' }}>
              <span className={'order-custom-title'}>Ship nội địa</span>
              <span className={'order-field-value'}>{convertCurrency(orderDetail?.inter_shipping_fee)}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className={'order-custom-title'}>Tổng tiền VND</span>
              <span className={'order-field-value'}>{convertCurrency(orderDetail?.totalAmount)}</span>
            </div>
            <Divider style={{ borderStyle: 'dashed', margin: '10px 0' }} />
            <FormProvider {...methods}>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '12px 0' }}>
                <span className={'order-custom-title'}>Giảm giá</span>
                <div style={{ display: 'flex' }}>
                  <InputField style={{ width: '100%' }} name={'discountValue'} />
                  <SelectCustom
                    name={'discountType'}
                    placeholder={'Chọn chi nhánh'}
                    options={discountData || []}
                    optionLabelKey={'name'}
                    style={{ width: '100%' }}
                    disableClear={true}
                  />
                </div>
              </div>
            </FormProvider>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '12px 0' }}>
              <span className={'order-custom-title'}>Thành tiền</span>
              <span className={'order-field-value'}>{convertCurrency(priceAfterDiscount)}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className={'order-custom-title'}>Đã thanh toán</span>
              <span className={'order-field-value'}>{convertCurrency(pricePay()?.totalPaid)}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '12px 0' }}>
              <span className={'order-custom-title'}>Số tiền cần thanh toán - 100%</span>
              <span className={'order-field-value'}>{convertCurrency(pricePay()?.totalNeedPaid)}</span>
            </div>
          </div>
          <Divider />
          {checkPayment()}
        </div>
      </Grid>
    );
  };

  return (
    <div className={'page-layout-blank'}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <div className={'page-layout'}>
            <Grid
              container
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              className="order-detail"
            >
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <h2 className={'txt-title'} style={{ whiteSpace: 'nowrap' }}>
                      Đơn hàng #{orderDetail?.orderNumber}
                    </h2>
                  </div>
                  <div style={{ padding: '2px 5px' }}>
                    <div
                      style={{
                        backgroundColor: checkStatusOrder(orderDetail?.status)?.backgroundColor,
                        borderRadius: '15px',
                      }}
                    >
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          color: 'white',
                          fontSize: '0.73rem',
                          fontWeight: '600',
                          padding: '2px 5px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {checkStatusOrder(orderDetail?.status)?.status}
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6} className="filter_order_right">
                <Button
                  style={{
                    padding: '1px 4px',
                    textTransform: 'none',
                  }}
                  onClick={() => setShowAddCustomer(true)}
                >
                  <BorderColorIcon color="primary" fontSize="small" />
                  <span
                    style={{
                      color: '#00354e ',
                      fontSize: '0.875rem',
                      fontWeight: '500',
                      padding: '2px 5px',
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '5px',
                    }}
                  >
                    Thay đổi
                  </span>
                </Button>
                <Button
                  style={{
                    backgroundColor: '#27c24c',
                    padding: '1px 4px',
                    textTransform: 'none',
                  }}
                  onClick={onCopyOrder}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: '0.7rem',
                      fontWeight: '600',
                      padding: '2px 5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Copy đơn hàng
                  </span>
                </Button>
                {orderDetail?.status !== 'CANCEL' && (
                  <Button
                    style={{
                      backgroundColor: '#F05050',
                      padding: '1px 4px',
                      textTransform: 'none',
                      marginLeft: '5px',
                      maxWidth: '120px',
                    }}
                    className="button-cancel"
                    onClick={() => setOpenModal(true)}
                  >
                    <span
                      style={{
                        color: 'white',
                        fontSize: '0.7rem',
                        fontWeight: '600',
                        padding: '2px 5px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Hủy đơn hàng
                    </span>
                  </Button>
                )}
              </Grid>
            </Grid>
            <Divider />
            <FormProvider {...methods}>
              <form>
                <div className="content mt-2">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Khách hàng*'} fullWidth>
                        <TextField
                          style={{
                            width: '100%',
                            textDecorationLine: 'underline',
                            color: '#1A89AC',
                          }}
                          // disabled={disabled}
                          inputProps={{ readOnly: disabled }}
                          className={'input-detail disabled-field'}
                          onClick={() => {
                            window.open(
                              `https://basso.vn/management/customer/detail/${orderDetail?.customerAddress?.oldId}`,
                              '_blank',
                            );
                          }}
                          value={orderDetail?.customerAddress?.name}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#038edb',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Địa chỉ'} fullWidth>
                        <TextField
                          style={{ width: '100%' }}
                          disabled={disabled}
                          className={'input-detail disabled-field'}
                          value={orderDetail?.customerAddress?.address}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Điện thoại'} fullWidth>
                        <TextField
                          style={{ width: '100%' }}
                          disabled={disabled}
                          className={'input-detail disabled-field'}
                          value={orderDetail?.customerAddress?.phone}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Tỉnh thành'} fullWidth>
                        <TextField
                          style={{ width: '100%' }}
                          disabled={disabled}
                          value={orderDetail?.customerAddress?.cities?.name}
                          className={'input-detail disabled-field'}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Email'} fullWidth>
                        <TextField
                          style={{ width: '100%' }}
                          disabled={disabled}
                          className={'input-detail disabled-field'}
                          value={orderDetail?.customerAddress?.email}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Quận huyện'} fullWidth>
                        <TextField
                          style={{ width: '100%' }}
                          value={orderDetail?.customerAddress?.district?.name}
                          disabled={disabled}
                          className={'input-detail disabled-field'}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Nơi chốt đơn'} fullWidth>
                        <TextField
                          style={{ width: '100%' }}
                          value={orderDetail?.placePickOrder}
                          disabled={disabled}
                          className={'input-detail disabled-field'}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControlCustom label={'Loại khách hàng'} fullWidth>
                        <TextField
                          style={{ width: '100%' }}
                          type="text"
                          value={
                            client_type?.[
                              detail?.data?.customerAddress?.customer?.groupId as keyof typeof client_type
                            ] || ''
                          }
                          disabled={disabled}
                          className={'input-detail disabled-field'}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              WebkitTextFillColor: '#000000',
                            },
                          }}
                        />
                      </FormControlCustom>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </FormProvider>
          </div>
          <div className={'page-layout'}>
            <Grid item className={'align-items-center header_title'}>
              <Grid item md={7}>
                <h2 className={'txt-title'}>SẢN PHẨM</h2>
              </Grid>
              {orderDetail.status === 'IN_REVIEW' || orderDetail.status === 'IN_PROCESS' ? (
                <div className="item-btn-right">
                  <Button onClick={() => setShowDrawer(true)} className={'btn-create'}>
                    <span className={'txt'}>Thêm sản phẩm</span>
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </Grid>
            <ProductList
              rows={orderDetail?.orderProducts || []}
              columns={columnsProductDetail}
              deleteProduct={onDeleteProduct}
              statusType={orderDetail?.status}
              onPressNote={onPressUpdateNote}
              textNote={onChangeNote}
              onChangeNote={note}
              onChangeQuantity={onChangeQuantity}
              onChangeSkuQuantity={onChangeSkuQuantity}
              getIdItem={getIdItem}
              shippingOrders={trackingOrder?.data.data ? trackingOrder.data.data : []}
            />
          </div>

          {checkTrackingOrder()}
        </Grid>
        <Grid item xs={12} md={4}>
          {checkInfoOrder()}
          {/* payment */}
          {checkPaymentOrder()}
        </Grid>
      </Grid>

      <div>
        <CreateProduct
          setShowDrawer={setShowDrawer}
          customerId={orderDetail?.customerId}
          showDrawer={showDrawer}
          type={'detail'}
          orderId={orderDetail?.id}
          refetchDetail={refetch}
          listItem={orderDetail?.orderProducts}
        />
        <AddCustomer
          setShowDrawer={setShowAddCustomer}
          showDrawer={showAddCustomer}
          data={orderDetail}
          refetch={refetch}
          type={'update'}
        />
        <UpdateCustomer
          setShowDrawer={setShowUpdateCustomer}
          showDrawer={showUpdateCustomer}
          data={orderDetail}
          refetch={refetch}
          type={'update'}
        />
        <AddTracking
          setShowDrawer={setShowAddTracking}
          showDrawer={showAddTracking}
          data={orderDetail || []}
          productList={orderDetail?.orderProducts}
          address={address}
          refetch={refetchTracking}
          refetchProd={refetch}
        />
        <UpdateTracking
          setShowUpdateDrawer={setShowUpdateTracking}
          showUpdateDrawer={showUpdateTracking.type === 'delivery' && showUpdateTracking.isOpen}
          isCanEdit={showUpdateTracking.type === 'refund'}
          data={detailTracking || []}
          productList={orderDetail?.orderProducts}
          address={address}
          refetch={refetchTracking}
        />
        <UpdateTrackingRefund
          data={detailTracking}
          refetch={refetchTracking}
          setShowDrawer={setShowUpdateTracking}
          showDrawer={showUpdateTracking.type === 'refund' && showUpdateTracking.isOpen}
        />
        <RefundOrder
          setShowDrawer={setShowRefundOrder}
          showDrawer={showRefundOrder}
          data={idOrder || []}
          productList={itemRefund?.data}
          address={address}
          refetch={refetchTracking}
          refetchProd={refetch}
          refundFetch={refundFetch}
        />
        <ModalAlert
          open={openModal}
          handleClose={() => handleCloseModal()}
          handleCancel={() => handleCloseModal()}
          handleConfirm={() => onCancelOrder()}
          title={''}
          description={''}
          icon={true}
          type={'error'}
          renderContentModal={
            <div className="view-input-discount">
              <span> Xác nhận hủy đơn hàng</span>
            </div>
          }
        />
        <Dialog open={showDiscountReason} onClose={() => setShowDiscountReason(false)}>
          <DialogTitle id="alert-dialog-title" style={{ width: '350px' }}>
            Lý do giảm giá
          </DialogTitle>
          <DialogContent>
            <TextareaAutosize
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                height: '100px',
                fontSize: '14px',
              }}
              placeholder={'Nhập lý do giảm giá'}
              value={discountReason}
              onChange={e => setValue('discountReason', e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDiscountReason(false)} size="medium">
              Huỷ
            </Button>
            <Button
              onClick={() => onCloseDiscountReason(orderDetail?.orderProducts)}
              autoFocus
              size="medium"
              variant="contained"
            >
              Xác nhận
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default OrderDetail;
